body {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "roboto";
  src: local("roboto"), url(./assets/fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "roboto-bold";
  src: local("roboto-bold"), url(./assets/fonts/Roboto-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "roboto-liht";
  src: local("roboto-light"), url(./assets/fonts/Roboto-Light.ttf) format("truetype");
}

body .slick-track {
  line-height: 0;
}
